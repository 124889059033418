import { ExpandMore, VolumeMute, VolumeUp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Slider, Stack, Typography } from "@mui/material";
import React from "react";
import { DataContextType } from "../types/store.types";
import { DataContext } from "../utils/StoreContext";

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 8,
        label: '25%',
    },
    {
        value: 17,
        label: '50%',
    },
    {
        value: 25,
        label: '75%',
    },
    {
        value: 33,
        label: '100%',
    },
];

export default function VolumeSlider() {

    const { setVolume, data } = React.useContext(DataContext) as DataContextType;

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setVolume(newValue);
        }
    };

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Volume {Math.round(data.volume * 3.017)}%</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"
                >
                    <VolumeMute />
                    <Slider aria-label="Volume"
                        min={0}
                        step={1}
                        max={33}
                        defaultValue={data.volume}
                        value={data.volume}
                        onChange={handleChange}
                        marks={marks}
                        valueLabelDisplay="auto"
                        valueLabelFormat={value => <div>{value}</div>}
                    />
                    <VolumeUp />
                </Stack>
            </AccordionDetails>
        </Accordion>);
}