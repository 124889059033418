import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Slider, Stack, Typography } from "@mui/material";
import React from "react";
import { DataContextType } from "../types/store.types";
import { DataContext } from "../utils/StoreContext";

const marks = [
  {
    value: 1.0,
    label: 'x1',
  },
];

export default function PitchShift() {

  const { setPitchShift, data } = React.useContext(DataContext) as DataContextType;

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setPitchShift(newValue);
    }
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Pitch Shift x{data.pitchShift}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"
        >
          <Slider aria-label="Volume"
            min={0.5}
            step={0.1}
            max={2.0}
            defaultValue={data.pitchShift}
            value={data.pitchShift}
            onChange={handleChange}
            marks={marks}
            valueLabelDisplay="auto"
            valueLabelFormat={value => <div>x{value}</div>}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>);
}