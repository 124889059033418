import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Slider, Stack, Typography } from "@mui/material";
import React from "react";
import { DataContextType } from "../types/store.types";
import { DataContext } from "../utils/StoreContext";

export default function Echo() {

    const { setEchoDelay, setEchoDepth, setEchoFeedback, data } = React.useContext(DataContext) as DataContextType;

    const handleDelayChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setEchoDelay(newValue);
        }
    };

    const handleDepthChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setEchoDepth(newValue);
        }
    };

    const handleFeedbackChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setEchoFeedback(newValue);
        }
    };

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Echo/Delay</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <Stack
                    spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"
                >

                    <Typography color="inherit">
                        Delay
                    </Typography>
                    <Slider aria-label="Duration"
                        min={0}
                        step={5}
                        max={1000}
                        defaultValue={data.echoDelay}
                        value={data.echoDelay}
                        onChange={handleDelayChange}
                        valueLabelDisplay="on"
                        valueLabelFormat={value => <div>{value} ms</div>}
                    />
                </Stack>
                <Stack
                    spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"
                >

                    <Typography color="inherit">
                        Depth
                    </Typography>
                    <Slider aria-label="Depth"
                        min={0}
                        step={0.01}
                        max={1}
                        defaultValue={data.echoDepth}
                        value={data.echoDepth}
                        onChange={handleDepthChange}
                        valueLabelDisplay="on"
                        valueLabelFormat={value => <div>{value}</div>}
                    />
                </Stack>
                <Stack
                    spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"
                >

                    <Typography color="inherit">
                        Feedback
                    </Typography>
                    <Slider aria-label="Feedback"
                        min={0}
                        step={0.01}
                        max={1}
                        defaultValue={data.echoFeedback}
                        value={data.echoFeedback}
                        onChange={handleFeedbackChange}
                        valueLabelDisplay="on"
                        valueLabelFormat={value => <div>{value}</div>}
                    />
                </Stack>


            </AccordionDetails>
        </Accordion>);
}