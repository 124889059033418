import Home from './pages/Home';
import DataProvider from './utils/StoreContext';
// import './App.css';

function App() {
  return (
    <DataProvider>
      <div className="App">
        <Home />
      </div>
    </DataProvider>
  );
}

export default App;
