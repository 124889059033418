import AppBar from '@mui/material/AppBar';
import MicIcon from '@mui/icons-material/Mic';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VolumeSlider from '../components/VolumeSlider';
import NoiseGate from '../components/NoiseGate';
import AudioLevel from '../components/AudioLevel';
import Equalizer from '../components/Equalizer';
import Echo from '../components/Echo';
import PitchShift from '../components/PitchShift';
import { Button } from '@mui/material';
import { DataContextType } from '../types/store.types';
import { DataContext } from '../utils/StoreContext';
import React from 'react';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://marcin.info">
        Marcin Kopa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function Home() {

  const { btConnect, btDisconnect, data } = React.useContext(DataContext) as DataContextType;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <MicIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
            SuperMic
          </Typography>
          <Button onClick={data.isConnected ? btDisconnect : btConnect}>{data.isConnected ? "DISCONNECT" : "CONNECT"}</Button>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            // pt: 8,
            // pb: 6,
          }}
        >
          <Container maxWidth="md" sx={{ marginTop: 1 }}>

            <AudioLevel />
            <VolumeSlider />
            <NoiseGate />
            <Equalizer />
            <Echo />
            <PitchShift />
          </Container>
        </Box>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          SuperMic
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
