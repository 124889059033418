import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { DataContextType } from "../types/store.types";
import { DataContext } from "../utils/StoreContext";

export default function AudioLevel() {

    const { data } = React.useContext(DataContext) as DataContextType;

    return (
        <Accordion defaultExpanded={true} >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Audio Level</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"
                >
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress sx={{ height: 15 }} variant="determinate" value={data.audioLevel} color="success" />
                    </Box>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}