import * as React from 'react';
import { DataContextType, DataProps, Props } from '../types/store.types';

export const DataContext = React.createContext<DataContextType | null>(null);

// https://lancaster-university.github.io/microbit-docs/resources/bluetooth/bluetooth_profile.html
// An implementation of Nordic Semicondutor's UART/Serial Port Emulation over Bluetooth low energy
// const UART_SERVICE_UUID = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
// const UART_SERVICE_UUID = "0000ffe1-0000-1000-8000-00805f9b34fb";
const UART_SERVICE_UUID = 0xFFE0;

// Allows the micro:bit to transmit a byte array
// const UART_TX_CHARACTERISTIC_UUID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
// const UART_TX_CHARACTERISTIC_UUID = "0000ffe1-0000-1000-8000-00805f9b34fb";
const UART_TX_CHARACTERISTIC_UUID = 0xFFE1;

// Allows a connected client to send a byte array
// const UART_RX_CHARACTERISTIC_UUID = "6e400003-b5a3-f393-e0a9-e50e24dcca9e";
// const UART_RX_CHARACTERISTIC_UUID = "0000ffe1-0000-1000-8000-00805f9b34fb";
const UART_RX_CHARACTERISTIC_UUID = 0xFFE1;

let uBitDevice: any;
let rxCharacteristic: any;


const DataProvider: React.FC<Props> = ({ children }) => {

    const [data, setData] = React.useState<DataProps>(
        {
            volume: 33,
            audioLevel: 0,
            noiseGate: 0,
            eq100: 0,
            eq150: 0,
            eq250: 0,
            eq400: 0,
            eq650: 0,
            eq1000: 0,
            eq1700: 0,
            eq2700: 0,
            eq4400: 0,
            eq7000: 0,
            echoDelay: 150,
            echoDepth: 0.5,
            echoFeedback: 0.5,
            pitchShift: 1.0,
            isConnected: false,
        },
    );

    const setVolume = (volume: number) => {
        setData((oldData) => ({
            ...oldData,
            volume
        }));
        sendBtMessage(`OV=${volume}\n`);
    };

    const setAudioLevel = (audioLevel: number) => {
        setData((oldData) => ({
            ...oldData,
            audioLevel: Math.round((100 / 5000) * audioLevel),
        }));
    };

    const setNoiseGate = (noiseGate: number) => {
        setData((oldData) => ({
            ...oldData,
            noiseGate
        }));
        sendBtMessage(`NG=${noiseGate}\n`);
    };

    const setEq100 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq100: value
        }));
        sendBtMessage(`E1=${value}\n`);
    };

    const setEq150 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq150: value
        }));
        sendBtMessage(`E2=${value}\n`);
    };

    const setEq250 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq250: value
        }));
        sendBtMessage(`E3=${value}\n`);
    };

    const setEq400 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq400: value
        }));
        sendBtMessage(`E4=${value}\n`);
    };

    const setEq650 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq650: value
        }));
        sendBtMessage(`E5=${value}\n`);
    };

    const setEq1000 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq1000: value
        }));
        sendBtMessage(`E6=${value}\n`);
    };

    const setEq1700 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq1700: value
        }));
        sendBtMessage(`E7=${value}\n`);
    };

    const setEq2700 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq2700: value
        }));
        sendBtMessage(`E8=${value}\n`);
    };

    const setEq4400 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq4400: value
        }));
        sendBtMessage(`E9=${value}\n`);
    };

    const setEq7000 = (value: number) => {
        setData((oldData) => ({
            ...oldData,
            eq7000: value
        }));
        sendBtMessage(`E10=${value}\n`);
    };



    const setEchoDelay = (echoDelay: number) => {
        setData((oldData) => ({
            ...oldData,
            echoDelay
        }));
        if (echoDelay === 0) {
            echoDelay = 1;
        }
        sendBtMessage(`DD=${echoDelay}\n`);
    };

    const setEchoDepth = (echoDepth: number) => {
        setData((oldData) => ({
            ...oldData,
            echoDepth
        }));
        sendBtMessage(`DE=${echoDepth * 100}\n`);
    };

    const setEchoFeedback = (echoFeedback: number) => {
        setData((oldData) => ({
            ...oldData,
            echoFeedback
        }));
        sendBtMessage(`DF=${echoFeedback * 100}\n`);
    };

    const setPitchShift = (pitchShift: number) => {
        setData((oldData) => ({
            ...oldData,
            pitchShift
        }));
        sendBtMessage(`PS=${pitchShift * 100}\n`);
    };

    const setIsConnected = (isConnected: boolean) => {
        setData((oldData) => ({
            ...oldData,
            isConnected
        }));
    };

    const btConnect = async () => {
        try {
            // console.log("Requesting Bluetooth Device...");
            if (!(navigator as any).bluetooth) {
                alert("The Bluetooth API is not supported in your browser.");
                return;
            }
            uBitDevice = await (navigator as any).bluetooth.requestDevice({
                filters: [{ services: [UART_SERVICE_UUID] }],
            });

            // console.log("Connecting to GATT Server...");
            const server = await uBitDevice.gatt.connect();

            // console.log("Getting Service...");
            const service = await server.getPrimaryService(UART_SERVICE_UUID);

            // console.log("Getting Characteristics...");
            const txCharacteristic = await service.getCharacteristic(
                UART_TX_CHARACTERISTIC_UUID
            );
            txCharacteristic.startNotifications();
            txCharacteristic.addEventListener(
                "characteristicvaluechanged",
                onTxCharacteristicValueChanged
            );
            rxCharacteristic = await service.getCharacteristic(
                UART_RX_CHARACTERISTIC_UUID
            );
            setIsConnected(true);
        } catch (error) {
            setIsConnected(false);
            console.log(error);
        }
    }

    function btDisconnect() {
        if (!uBitDevice) {
            setIsConnected(false);
            return;
        }

        if (uBitDevice.gatt.connected) {
            uBitDevice.gatt.disconnect();
            setIsConnected(false);
            // console.log("Disconnected");
        }
    }

    async function sendBtMessage(message: string) {
        if (!rxCharacteristic || !data.isConnected) {
            return;
        }

        try {
            let encoder = new TextEncoder();
            rxCharacteristic.writeValue(encoder.encode(message));
        } catch (error) {
            console.log(error);
        }
    }

    function onTxCharacteristicValueChanged(event: any) {
        let receivedData = [];
        for (var i = 0; i < event.target.value.byteLength; i++) {
            receivedData[i] = event.target.value.getUint8(i);
        }

        const receivedString = String.fromCharCode.apply(null, receivedData).trim();
        const [command, value] = receivedString.split("=");
        switch (command) {
            case "SM":
                setAudioLevel(+value);
                break;
            default:
                break;
        }
        console.log(receivedString);
        // console.log("command: ", command, " value: ", value);
    }

    // function randomIntFromInterval(min: number, max: number) { // min and max included 
    //     return Math.floor(Math.random() * (max - min + 1) + min)
    // }

    // setInterval(() => {
    //     setAudioLevel(randomIntFromInterval(0, 100));
    // }, 100);

    return <DataContext.Provider value={{
        data,
        setVolume,
        setAudioLevel,
        setNoiseGate,
        setEq100,
        setEq150,
        setEq250,
        setEq400,
        setEq650,
        setEq1000,
        setEq1700,
        setEq2700,
        setEq4400,
        setEq7000,
        setEchoDelay,
        setEchoDepth,
        setEchoFeedback,
        setPitchShift,
        setIsConnected,
        btConnect,
        btDisconnect,
    }}>{children}</DataContext.Provider>;
};

export default DataProvider;