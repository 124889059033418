import { ExpandMore, VolumeMute, VolumeUp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Slider, Stack, Typography } from "@mui/material";
import React from "react";
import { DataContextType } from "../types/store.types";
import { DataContext } from "../utils/StoreContext";

export default function NoiseGate() {

    const { setNoiseGate, data } = React.useContext(DataContext) as DataContextType;

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setNoiseGate(newValue);
        }
    };

    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Noise Gate {Math.round((100 / 1000) * data.noiseGate)}%</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"
                >
                    <VolumeMute />
                    <Slider aria-label="NoiseGate"
                        min={0}
                        step={1}
                        max={1000}
                        defaultValue={data.noiseGate}
                        value={data.noiseGate}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        valueLabelFormat={value => <div>{Math.round((100 / 1000) * value)}%</div>}
                    />
                    <VolumeUp />
                </Stack>
            </AccordionDetails>
        </Accordion>);
}