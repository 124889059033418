import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Slider, Typography } from "@mui/material";
import React from "react";
import { DataContextType } from "../types/store.types";
import { DataContext } from "../utils/StoreContext";

const marks = [
  {
    value: -20,
    label: '',
  },
  {
    value: -12,
    label: '',
  },
  {
    value: -6,
    label: '',
  },
  {
    value: -3,
    label: '',
  },
  {
    value: 0,
    label: '',
  },
  {
    value: 3,
    label: '',
  },
  {
    value: 6,
    label: '',
  },
  {
    value: 12,
    label: '',
  },
  {
    value: 20,
    label: '',
  },
];

export default function Equalizer() {

  const { data, setEq100, setEq150, setEq250, setEq400, setEq650, setEq1000, setEq1700, setEq2700, setEq4400, setEq7000, } = React.useContext(DataContext) as DataContextType;

  const handle100Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq100(newValue);
    }
  };

  const handle150Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq150(newValue);
    }
  };

  const handle250Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq250(newValue);
    }
  };

  const handle400Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq400(newValue);
    }
  };

  const handle650Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq650(newValue);
    }
  };

  const handle1000Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq1000(newValue);
    }
  };

  const handle1700Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq1700(newValue);
    }
  };

  const handle2700Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq2700(newValue);
    }
  };

  const handle4400Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq4400(newValue);
    }
  };

  const handle7000Change = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setEq7000(newValue);
    }
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>10 Band Parametric EQ +-20 dB</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <Box sx={{ flexGrow: 0, p: 0 }} alignContent="center" alignItems="center">
          <Grid container spacing={2} alignItems="center" alignContent="center" alignSelf="center" >
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item >
              <Typography id="non-linear-slider" color="text.secondary" alignContent="center" >
                100
              </Typography>
              <Slider
                aria-label="FreqLow"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq100}
                value={data.eq100}
                onChange={handle100Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary" alignItems="center">
                {data.eq100}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item>
              <Typography color="text.secondary">
                150
              </Typography>
              <Slider
                aria-label="FreqMedium"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq150}
                value={data.eq150}
                onChange={handle150Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq150}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item >
              <Typography id="non-linear-slider" alignContent="center" color="text.secondary">
                250
              </Typography>
              <Slider
                aria-label="FreqHigh"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq250}
                value={data.eq250}
                onChange={handle250Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq250}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item >
              <Typography id="non-linear-slider" alignContent="center" color="text.secondary">
                400
              </Typography>
              <Slider
                aria-label="FreqHigh"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq400}
                value={data.eq400}
                onChange={handle400Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq400}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item>
              <Typography id="non-linear-slider" alignContent="center" color="text.secondary">
                650
              </Typography>
              <Slider
                aria-label="FreqHigh"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq650}
                value={data.eq650}
                onChange={handle650Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq650}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item >
              <Typography alignContent="center" color="text.secondary">
                1.0k
              </Typography>
              <Slider
                aria-label="FreqHigh"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq1000}
                value={data.eq1000}
                onChange={handle1000Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq1000}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item >
              <Typography id="non-linear-slider" alignContent="center" color="text.secondary">
                1.7k
              </Typography>
              <Slider
                aria-label="FreqHigh"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq1700}
                value={data.eq1700}
                onChange={handle1700Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq1700}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item >

              <Typography id="non-linear-slider" alignContent="center" color="text.secondary">
                2.7k
              </Typography>
              <Slider
                aria-label="FreqHigh"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq2700}
                value={data.eq2700}
                onChange={handle2700Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq2700}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item >
              <Typography id="non-linear-slider" alignContent="center" color="text.secondary">
                4.4k
              </Typography>
              <Slider
                aria-label="FreqHigh"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq4400}
                value={data.eq4400}
                onChange={handle4400Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq4400}
              </Typography>
            </Grid>
            <Grid xs={1} height={250} minWidth={35} marginBottom={5} item >
              <Typography id="non-linear-slider" alignContent="center" color="text.secondary">
                7.0k
              </Typography>
              <Slider
                aria-label="FreqHigh"
                orientation="vertical"
                min={-20}
                step={1}
                max={20}
                defaultValue={data.eq7000}
                value={data.eq7000}
                onChange={handle7000Change}
                valueLabelDisplay="off"
                valueLabelFormat={value => <div>x{value}</div>}
                marks={marks}
              />
              <Typography color="text.secondary">
                {data.eq7000}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}